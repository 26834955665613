var render = function () {
  var _vm$combinationSelect;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.isEmpty(_vm.selectedTrip) ? _c('div', [_c('ReservationBook', {
    attrs: {
      "selected-trip-props": _vm.selectedTrip,
      "is-combination": false
    }
  })], 1) : _vm.combinationSelectedTrip ? _c('div', [_c('ReservationBook', {
    attrs: {
      "selected-trip-props": _vm.combinationSelectedTrip.journeys,
      "platingAirline": (_vm$combinationSelect = _vm.combinationSelectedTrip.airline) !== null && _vm$combinationSelect !== void 0 ? _vm$combinationSelect : null,
      "is-combination": true
    }
  })], 1) : _c('div', [_c('b-alert', {
    staticClass: "px-2 py-1",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" Không có chuyến bay nào được chọn. Vui lòng chọn lại ")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }